/* Color Palette
  Accent: #0070E2;
  Shadow: #041D4A;
*/
/* Google Fonts
  font-family: 'Tauri', sans-serif;
  font-family: 'Domine', serif;
  font-family: 'Source Sans Pro', sans-serif;
*/
* {
  box-sizing: border-box;
}

html {
  background-color: #222;
  margin: 0;
}

html, body, .App, .App > div {
  min-height: 100vh;
}

body {
  font-size: 62.5%;
}

.App {
  margin: 0;
  background-color: #fff;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
}

/* My Styles */

a:link, a:visited {
  text-decoration: none;
  color: #0070E2;
}

a:hover, a:active {
  color: #0981D2;;
}

/***** Header Styles *****/

.App header {
  background-color: #222;
}

.App header .Banner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 2em;
  margin: 0 1% 0 1%;
  padding-bottom: 0;
  /* https://css-tricks.com/almanac/properties/u/user-select/ */
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
}

.App header .Banner h1 {
  font-family: 'Tauri', sans-serif;
  /*letter-spacing: 3px;*/
  color: white;
}

.App header .Banner h2 {
  color: #0070E2;
}

.App header .Banner .MyStuff {
  width: 30%;
  height: auto;
  padding: 0;
}

.App header .Banner .MyStuff img {
  margin: 0 5%;
  /*padding: ;*/
  width: 10%;
  height: auto;
}

.App header .Banner .MyStuff .icon-id {
  color: white;
}

/***** Nav Styles *****/

.App nav {
  /*padding-bottom: .1%;*/
}

.App nav ul {
  padding: 0;
  margin-top: 0;
}

.App nav li {
  margin: 0 5%;
  display: inline-block;
  list-style: none;
  padding: 1.5% 1%;
  width: 10%;
  height: 1.5%;
 /* border: 2px #0070E2 solid;
  border-radius: 5px;*/
  font-size: 1.8em;
  font-weight: 600;
}

.App nav li a:link, a:visited {
  color: white;
}

.App nav li:hover {
  /* background-color: #0070E2; */
  /* border-radius: 5px 5px 0 0; */
  color: white;
}

.App nav li:hover::after {
  color: #0070E2;
  content: " >";
  font-weight: 800;
}

.App nav li:hover::before {
  color: #0070E2;
  content: "< ";
  font-weight: 800;
}

.App main {
  margin: 0 auto;
  padding-bottom: 10%;
  width: 80%;
  height: 100%;
}

/***** Home Styles *****/

.App main .home {
  /*border: 1px red solid;*/
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
}

.App main .home img {
  width: 60%;
  height: 60%;
  border-radius: 10px;
  opacity: 0.9;
}

.App main .home .passions {
  width: 50%;
  height: 50%;
  font-size: 2vw;
  color: black;
  text-align: left;
}

.App main .home .passions #text-passions {
  color: #4169E1;
}

.App main .home .passions #text-passion {
  color: #FF7F50;
}

/***** About Styles *****/

.App main .About {
  text-align: left;
  display: flex;
  flex-direction: row;
}

.App main .About img {
  margin: 0 auto;
  text-align: center;
  border: 5px #0070E2 solid;
  border-radius: 50%;
  width: 30%;
  height: 30%;
  opacity: 0.9;
  flex-basis: 30%;
}

.App main .About .AboutMe {
  flex-basis: 50%;
}

.App main .About .AboutMe p {
  font-family: 'Domine', serif;
  font-size: 1.8em;
}

/***** Projects Styles *****/

::-webkit-scrollbar {
  display:none;
}

.App main .Projects h2 {
  /*color: #0070E2;*/
  font-size: 2.4em;
}

.App main .Projects {
  display: flex;
  justify-content: center;
}

.App main .Projects ul {
  padding: 0;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-items: center;
}

.App main .Projects li {
  list-style: none;
  padding: 1%;
  width: auto;
  height: 100%;
  border: 2px #0070E2 solid;
  border-radius: 5px;
}

.App main .Projects li img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.App main .Projects img:hover {
  cursor: pointer;
  background-color: #0070E2;
  color: white;
}

/*** Individual Project Styles ***/

.App main .Projects .project {
  margin: 0 auto;
  width: 75%;
}

.App main .Projects .project .show-gallery-btn {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
  width: 2rem;
  height: 2rem;
  color: #0070E2;
  font-size: 2em;
  font-weight: 600;
  border: 2px #0070E2 solid;
  border-radius: 5px;
}

.App main .Projects .project .show-gallery-btn:hover {
  background-color: #0070E2;
  color: white;
  cursor: pointer;
}

.App main .Projects .web-project {
  padding: 1%;
  /*background-color: rgba(22, 22, 22, .2);*/
  border: 2px #0070E2 solid;
  border-radius: 5px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.App main .Projects .project-image {
  width: 100%;
  height: auto;
}

.App main .Projects .web-project .web-project-info {
  padding: 2%;
  font-size: 1.6em;
  font-family: 'Domine', serif;
  font-weight: 100;
  color: black;
  text-align: left
}

.App main .Projects .web-project .web-project-info span {
  color: #0070E2;
  font-weight: 600;
  font-family: sans-serif;
}

.App main .Projects .web-project .web-project-info .icon {
  width: 10%;
  height: auto;
  margin: 0 5% 0 0;
  height: auto;
  text-align: center;
  text-align: center;
}

.App main .Projects .web-project .web-project-info .icon:hover {
  height: auto;
  text-align: center;
  background-color: #041D4A;
  border-radius: 50%;
}

/*** Div Animations ***/
/*
Div Anims From:
https://codepen.io/jmelgoza/pen/jEaGYg?editors=0110
*/
/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fade-in {
  opacity:0;  /* make things invisible upon start */
  -webkit-animation:fadeIn ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:0.75s;
  -moz-animation-duration:0.75s;
  animation-duration:0.75s;
}

.fade-in.one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.fade-in.two {
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.fade-in.three {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.fade-in.four {
  -webkit-animation-delay: 1.25s;
  -moz-animation-delay: 1.25s;
  animation-delay: 1.25s;
}

/***** Contact Styles *****/

.App main .Contact {
  text-align: center;
  font-size: 1.2rem;
}

.App main .Contact a {
  padding: 0 0.5rem;
  color: #0070E2;
}

.App main .Contact .contact-form {
  margin: 3% auto;
  padding: 3%;
  width: 50%;
  background-color: rgba(22, 22, 22, .9);
  color: white;
  border-radius: 5px;
}

.App main .Contact input {
  width: 70%;
  font-size: 1.2rem;
  height: auto;
}

.App main .Contact textarea {
  width: 70%;
  font-size: 1.2rem;
  height: auto;
}

.App main .Contact button {
  /*width: 10%;
  height: 50%;*/
  background-color: rgba(0, 0, 0, 0);
  color: white;
  padding: 1% 5%;
  font-size: 1.2rem;
  border: 2px #0070E2 solid;
}

.App main .Contact button:hover {
  background-color: #0070E2;
  color: white;
}

/***** Form Response Styles *****/
.App main .form-response {
  margin-top: 100px;
  font-size: 1.2rem;
}

/***** Footer Styles *****/

.App footer {
  margin: 0;
  width: 100%;
  height: 5%;
  position: fixed;
  bottom: 0;
  background-color: #222;
  color: white;
  display: flex;
  justify-content: space-between;
  font-size: 2em;
}

.App footer span {
  margin: 0.5em auto;
  width: auto;
}

.App footer a:hover, a:active {
  color: #0070E2;
}

/* Large Devices, Wide Screens */
@media screen and (max-width : 1200px) {
  .App main {
    /*background-color: green;*/
  }

  .App main .Projects .ProjectsGallery ul li {
    font-size: 1em;
  }

  /*** Individual Project Styles ***/

  .App main .Projects .project {
    margin: 0 auto;
    width: 85%;
  }

}

/* Medium Devices, Desktops */
@media screen and (max-width: 992px) {
  /***** Projects Styles *****/

  .App main {
    /*background-color: blue;*/
  }

  /***** Header Styles *****/


  /***** Nav Styles *****/

  .App nav li {
/*    margin: 0 5%;*/
    display: inline-block;
    padding: 1em;
    width: auto;
    font-size: 2em;
    font-weight: 500;
  }

  .App nav li:hover {
    padding: 1em;
  }

  /***** Home Styles *****/

  .App main {
    width: 90%;
  }

  .App main .home {
    /*border: 1px red solid;*/
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  .App main .home img {
    width: 60%;
    height: 60%;
    border-radius: 10px;
    opacity: 0.9;
  }

  .App main .home .passions {
    width: 60%;
    height: 50%;
    font-size: 1.6em;
  }

  /***** About Styles *****/

  .App main .About {
    margin: 10% auto;
  }

  .App main .About img {
    margin: 2em auto;
    width: 30%;
    height: 30%;
    flex-basis: 30%;
  }

  .App main .About .AboutMe {
    flex-basis: 50%;
  }

  .App main .About .AboutMe p {
    font-size: 1.6em;
  }

  /***** Projects Styles *****/




  /*** Individual Project Styles ***/

  .App main .Projects .project {
    margin: 0 auto;
    width: 90%;
  }

  .App main .Projects .project .show-gallery-btn {
    width: 2rem;
    height: 2rem;
    /* padding: 0.2em; */
    color: #0070E2;
    font-size: 2em;
    font-weight: 600;
  }

  .App main .Projects .web-project {
    padding: 1em;
    display: flex;
    flex-flow: column;
  }

  .App main .Projects .project-image {
    width: 100%;
    height: auto;
  }

  .App main .Projects .web-project .web-project-info {
    padding: 2%;
    font-size: 1.6em;
  }

  .App main .Projects .web-project .web-project-info .icon {
    width: 8%;
    height: auto;
    margin: 0 8% 0 0;
    height: auto;
  }

  /***** Contact Styles *****/

  .App main .Contact {
    margin: 10% auto;
    font-size: 2em;
  }

  .App main .Contact .contact-form {
    margin: 3% auto;
    padding: 3%;
    width: 80%;
  }

  .App main .Contact input {
    width: 70%;
    font-size: 0.8em;
    height: auto;
  }

  .App main .Contact textarea {
    width: 70%;
    font-size: 0.8em;
    height: auto;
  }

  .App main .Contact button {
    width: 30%;
    height: auto;
    padding: 1em;
    font-size: 0.8em;
  }

  /***** Footer Styles *****/

  .App footer {
    position: fixed;
    font-size: 1.6em;
  }

  .App footer span {
    padding: 1%;
  }

}

/* Small Devices, Tablets */
@media screen and (max-width : 768px) {
  .App nav li {
    font-size: 1.8em;
  }

  .App main {
    /*background-color: red;*/
  }

  /***** Home Styles *****/

  .App main .home {
    margin: 10% auto;
  }

  .App main .home img {
    width: 65%;
    height: 65%;
  }

  .App main .home .passions {
    font-size: 2em;
  }

  /***** About Styles *****/

  .App main .About {
    margin: 10% auto;
    display: flex;
    flex-direction: column;
  }

  .App main .About img {
    margin: 2em auto;
    width: 30%;
    height: 30%;
    flex-basis: 30%;
  }

  .App main .About .AboutMe {
    flex-basis: 50%;
  }

  .App main .About .AboutMe p {
    font-size: 1.8em;
  }

  /***** Projects Styles *****/

  .App main .Projects .ProjectsGallery {
    margin: 0 auto;
  }

  .App main .Projects h2 {
    /*color: #0070E2;*/
    font-size: 2em;
  }

  .App main .Projects .ProjectsGallery {
  }
  
  .App main .Projects ul {
    margin: 0 auto;
    width: 160%;
    padding: 0;
    /* grid-template-columns: 80%; */
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    
  }

  .App main .Projects li {
    margin: 1em;
    padding: 1em;
    width: 60%;
    height: auto;
  }

  .App main .Projects li img {
    width: 100%;
    height: auto;
  }


  /*** Individual Project Styles ***/

  .App main .Projects .project {
    width: 90%;
  }

  .App main .Projects .project .show-gallery-btn {
    width: 2rem;
    height: 2rem;
    /* padding: 0.2em; */
    color: #0070E2;
    font-size: 2em;
    font-weight: 600;
  }

  .App main .Projects .web-project {
    padding: 1em;
    display: flex;
    flex-flow: column;
  }

  .App main .Projects .project-image {
    width: 100%;
    height: auto;
  }

  .App main .Projects .web-project .web-project-info {
    padding: 2%;
    font-size: 1.8em;
  }

  .App main .Projects .web-project .web-project-info .icon {
    width: 10%;
    height: auto;
    margin: 0 10% 0 0;
    height: auto;
  }

  /***** Contact Styles *****/

  .App main .Contact {
    margin: 10% auto;
    font-size: 2em;
  }

  .App main .Contact .contact-form {
    margin: 3% auto;
    padding: 3%;
    width: 80%;
  }

  .App main .Contact input {
    width: 70%;
    font-size: 1em;
    height: auto;
  }

  .App main .Contact textarea {
    width: 70%;
    font-size: 1em;
    height: auto;
  }

  .App main .Contact button {
    width: 30%;
    height: auto;
    padding: 1em;
    font-size: 0.8em;
  }

  /***** Footer Styles *****/

  .App footer {
    position: fixed;
    font-size: 2em;
  }

  .App footer span {
    padding: 1%;
  }

}

@media screen and (max-width : 650px) {
  /***** Header Styles *****/
  .App header .Banner {
    display: flex;
    flex-flow: column nowrap;
    font-size: 1em;
  }

  .App header .Banner h1 {
    margin: 0.5em auto;
  }

  .App header .Banner h2 {
    margin: 0.5em auto;
    font-size: 2em;
  }
}

/* Extra Small Devices, Phones */
@media screen and (max-width : 528px) {
  .App main {
    /*background-color: yellow;*/
  }

  /***** Header Styles *****/

  .App header .Banner {
    display: flex;
    flex-flow: column nowrap;
    font-size: 1em;
  }

  .App header .Banner h1 {
    margin-top: 0.5em;
  }

  .App header .Banner h2 {
    margin-top: 0;
    font-size: 2em;
  }

  .App header .Banner .MyStuff {
    width: 80%;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
  }

  .App header .Banner .MyStuff img {
    margin: 0 10%;
    width: 10%;
    height: auto;
  }

  .App header .Banner .MyStuff .icon-id {
    visibility: hidden;
  }

  /***** Nav Styles *****/

  .App nav li {
/*    margin: 0 5%;*/
    display: inline-block;
    padding: 0 0.6%;
    width: auto;
    font-size: 1.5em;
    font-weight: 500;
  }

  .App nav li:hover {
    width: auto;
    padding: 0.5em;
  }

  /***** Home Styles *****/

  .App main {
    width: 90%;
  }

  .App main .home {
    /*border: 1px red solid;*/
    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: space-around;
    align-items: center;
  }

  .App main .home img {
    align-self: flex-start;
    width: 80%;
    height: 80%;
    border-radius: 10px;
    opacity: 0.9;
  }

  .App main .home .passions {
    align-self: flex-end;
    width: 70%;
    height: 50%;
    font-size: 2em;
  }

  /***** About Styles *****/

  .App main .About {
    margin: 10% auto;
    display: flex;
    flex-direction: column;
  }

  .App main .About img {
    margin: 2em auto;
    width: 40%;
    height: 40%;
    flex-basis: 30%;
  }

  .App main .About .AboutMe {
    flex-basis: 50%;
  }

  .App main .About .AboutMe p {
    font-size: 1.6em;
  }

  /***** Projects Styles *****/

  .App main .Projects {

  }

  .App main .Projects h2 {
    /*color: #0070E2;*/
    font-size: 2em;
  }

  .App main .Projects li {
    margin: 2em;
    padding: 2em;
  }


  /*** Individual Project Styles ***/

  .App main .Projects .project {
    width: 90%;
  }

  .App main .Projects .project .show-gallery-btn {
    width: 2rem;
    height: 2rem;
    /* padding: 0.2em; */
    color: #0070E2;
    font-size: 2em;
    font-weight: 600;
  }

  .App main .Projects .web-project {
    padding: 1em;
    display: flex;
    flex-flow: column;
  }

  .App main .Projects .project-image {
    width: 100%;
    height: auto;
  }

  .App main .Projects .web-project .web-project-info {
    padding: 2%;
    font-size: 1.6em;
  }

  .App main .Projects .web-project .web-project-info .icon {
    width: 13%;
    height: auto;
    margin: 0 13% 0 0;
    height: auto;
  }

  /***** Contact Styles *****/

  .App main .Contact {
    margin: 10% auto;
    font-size: 2em;
  }

  .App main .Contact .contact-form {
    margin: 3% auto;
    padding: 3%;
    width: 80%;
  }

  .App main .Contact input {
    width: 70%;
    font-size: 0.8em;
    height: auto;
  }

  .App main .Contact textarea {
    width: 70%;
    font-size: 0.8em;
    height: auto;
  }

  .App main .Contact button {
    width: 30%;
    height: auto;
    padding: 1em;
    font-size: 0.8em;
  }

  /***** Footer Styles *****/

  .App footer {
    position: fixed;
    font-size: 1.6em;
  }

  .App footer span {
    padding: 1%;
  }
}

/* Custom, iPhone Retina */
@media screen and (max-width : 320px) {
  .App main {
    /*background-color: orange;*/
  }

    /***** Header Styles *****/

  .App header .Banner {
    display: flex;
    flex-flow: column nowrap;
    font-size: 1em;
  }

  .App header .Banner h1 {
    margin-top: 0.5em;
  }

  .App header .Banner h2 {
    margin-top: 0;
    font-size: 2em;
  }

  .App header .Banner .MyStuff {
    width: 80%;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
  }

  .App header .Banner .MyStuff img {
    margin: 0 10%;
    width: 10%;
    height: auto;
  }

  .App header .Banner .MyStuff .icon-id {
    visibility: hidden;
  }

  /***** Nav Styles *****/

  .App nav li {
    margin: 0 3%;
    display: inline-block;
    padding: 0 0.1%;
    width: auto;
    font-size: 1.5em;
    font-weight: 500;
  }

  .App nav li:hover {
    width: auto;
    padding: 0.5em;
  }

  /***** Home Styles *****/

  .App main {
    width: 90%;
  }

  .App main .home {
    /*border: 1px red solid;*/
    display: flex;
    flex-flow: column-reverse nowrap;
    justify-content: space-around;
    align-items: center;
  }

  .App main .home img {
    align-self: flex-start;
    width: 80%;
    height: 80%;
  }

  .App main .home .passions {
    align-self: flex-end;
    width: 70%;
    height: 50%;
    font-size: 2em;
  }

  /***** About Styles *****/

  .App main .About {
    margin: 10% auto;
    display: flex;
    flex-direction: column;
  }

  .App main .About img {
    margin: 2em auto;
    width: 40%;
    height: 40%;
    flex-basis: 30%;
  }

  .App main .About .AboutMe {
    flex-basis: 50%;
  }

  .App main .About .AboutMe p {
    font-size: 1.6em;
  }

  /***** Projects Styles *****/

  .App main .Projects {

  }

  .App main .Projects h2 {
    /*color: #0070E2;*/
    font-size: 2em;
  }

  .App main .Projects li {
    margin: 1em;
    padding: 0.5em;
  }


  /*** Individual Project Styles ***/

  .App main .Projects .project {
    width: 90%;
  }

  .App main .Projects .project .show-gallery-btn {
    width: 2rem;
    height: 2rem;
    /* padding: 0.2em; */
    color: #0070E2;
    font-size: 2em;
    font-weight: 600;
  }

  .App main .Projects .web-project {
    padding: 1em;
    display: flex;
    flex-flow: column;
  }

  .App main .Projects .project-image {
    width: 100%;
    height: auto;
  }

  .App main .Projects .web-project .web-project-info {
    padding: 2%;
    font-size: 1.6em;
  }

  .App main .Projects .web-project .web-project-info .icon {
    width: 13%;
    height: auto;
    margin: 0 13% 0 0;
    height: auto;
  }

  /***** Contact Styles *****/

  .App main .Contact {
    margin: 10% auto;
    font-size: 1.8em;
  }

  .App main .Contact .contact-form {
    margin: 3% auto;
    padding: 3%;
    width: 80%;
  }

  .App main .Contact input {
    width: 70%;
    font-size: 0.6em;
    height: auto;
  }

  .App main .Contact textarea {
    width: 70%;
    font-size: 0.8em;
    height: auto;
  }

  .App main .Contact button {
    width: 30%;
    height: auto;
    padding: 1em;
    font-size: 0.8em;
  }

  /***** Footer Styles *****/

  .App footer {
    position: fixed;
    font-size: 1.4em;
  }

  .App footer span {
    padding: 1%;
  }
}
